@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

ul,
ol {
  // list-style: none;
}


:root {
  --gary: #6f6f6f;
  --maxWidth: 1100px;
}

.body {
  font-smooth: unset !important;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial !important;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

.weight3 {
  font-weight: 300;
}

:global {
  // .noBorder {
  //   border: none;
  // }

  .button {
    background-color: #ffffff;
    padding: 2px 10px;
    border: 1px solid #e8e8e8;
    height: 32px;
    border-radius: 2px;
    cursor: pointer;
  }

}


a:hover {
  color: #ac78ff !important;
}

a:not(:hover) {
  color: inherit;
}