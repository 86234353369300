:root {
    --main_width:  1000px;
    --main_color: #404040;

    --detail_width: 1200px;
    /* // color */
    --text-color: #252933;
    --text-second-color: #515767;
    --text-third-color: #8a919f;
    --border-color: #e8e8e8e8;
    --block-color: #fff;
    /* // bg color */
    --body-bg-color: #f3f3f3;

    --error-color: #ff4d4f;

    /* // border */
    /* --radius: 4px; */
    --block-radius: 4px;
    --article-content-width: 820px;

    @media screen and (max-width: 768px) {
        --main_width: 100%;
    }
}