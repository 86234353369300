@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
.login {
  background-color: #ffffff;
  padding: 10px;
  .content {
    display: flex;
  }
  .main {
    flex: 1;
    padding: 32px;
  }
  .methods {
    margin-top: 12px;
    width: 300px;
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
    .list {
      margin-top: 20px;
    }
    button {
      border: 1px solid #e8e8e8;
      padding: 6px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 3px;
    }
    .github_icon {
      display: inline-block;
      mask-image: url("/static/images/github.svg");
      height: 24px;
      width: 24px;
      mask-size: contain;
      background-color: #000000;
      margin-right: 4px;
    }
  }
  .email_login {
    width: 400px;
    margin: auto;
  }
  .registry_success {
    text-align: center;
  }
}
