@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
.layout {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  flex-direction: column !important;
  background: transparent !important;

  &-content {
    flex: 1;
    margin-bottom: 24px;
    background-color: #fff;
    overflow: hidden;
    width: unset !important;
  }

  :global {
    .ant-layout-header {
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
      position: sticky;
      top: 0;
      right: 0;
      left: 0;
      z-index: 999;
    }

    .ant-layout-content {
      min-height: calc(100vh - 133px);
      flex: none;
    }

    .ant-layout-footer {
      background-color: #fff;
      padding: 12px 120px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    width: 100%;
    margin: 0 auto;

    &-item {
      display: flex;
      align-items: center;
    }

    .metaInfo {
      .about {
        color: inherit;

        :global {
          .ant-divider {
            color: inherit !important;
            background-color: currentColor !important;
          }
        }

        a {
          margin: 0;
        }
      }
    }

    .beian {
      margin-top: 8px;

      a {
        margin: 0;
      }
    }

    &_contact {
      cursor: pointer;
    }

    .upyun {
      height: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  .layout {
    :global {
      .ant-layout-header {
        padding: 0 16px;
      }

      .ant-layout-footer {
        padding: 12px 20px;
      }
    }
  }
}